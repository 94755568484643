import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"

import styles from "./post.teaser.module.css"

export default function PostTeaser ({post, lang}) {

  return <StaticQuery
        query={graphql`
            query {
              site {
                siteMetadata {
                  readMore {
                    ru,
                    en
                  }
                }
              }
            }
            `}
        render={(data)=>(
            <section className={styles.teaser}> 
                <Link to={post.fields.slug}>
                  <span className={styles.date}>[{ post.frontmatter.date }]</span><h3 style={ {display:"inline"} }>{ post.frontmatter.title}</h3>
                </Link>
                <p> { post.excerpt } </p>
                <p className={styles.tags}> { post.frontmatter.tags }</p>
                <Link className={styles.more} to={post.fields.slug}>
                  {data.site.siteMetadata.readMore[lang]}
                </Link>
            </section>
  )} />
}