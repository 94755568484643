import React from "react"
import { graphql } from "gatsby";
import Layout from "../../component/layout";
import PostTeaser from "../../component/post.teaser"
import Meta from "../../component/meta"

export default function EnIndex ({data, location}) {
  let lang = location.pathname.split('/')[1];
  return (
<React.Fragment>
<Meta
  title="Рома Гуйван: Моя домашняя страничка и блог"
  lang="ru"
  keywords={[]}></Meta>
  <Layout language={lang}>
   <h1>Мой сайт-помоечка для ВАЖНОЙ информации. Ин ингуриш АНД РАШН</h1>
    { data.allMarkdownRemark.edges.map(edge => (
        <PostTeaser key={edge.node.id} post={edge.node} lang={lang}>
        </PostTeaser>
    )) }
    </Layout>
</React.Fragment>)
}

export const query = graphql`
{
  allMarkdownRemark(
    filter: { frontmatter: { lang: { eq: "ru" } } },
    sort: {fields: [frontmatter___date], order: DESC}
  ) {
    totalCount
 		edges {
        node {
          id,
          fields {
              slug
          }
          frontmatter {
            title,
            tags,
            date(formatString: "DD MMMM, YYYY", locale: "uk")
          },
          excerpt
        }
      }
  }
}
`